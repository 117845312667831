<template>
  <div
    class="Footer"
    :class="{
      'background-green-strong': $route.name === 'Contact',
      'background-beige': $route.name === 'CommunityImpact',
    }"
    style="overflow: hidden;"
  >
    <v-footer class="footer-new" color="transparent" v-if="$vuetify.breakpoint.xs">
      <!-- contenido mobile -->
      <template v-if="$vuetify.breakpoint.xs">
        <v-img
          :src="require('@/assets/img/footer-back-mobile.png')"
          class="img-footer-new"
          contain
          position="center bottom"
        >
          <v-container class="fill-height">
            <v-row
              class="white--text position-relative footer-row text-center"
              align="center"
              justify="center"
            >
              <v-col cols="12" sm="auto">
                <v-img
                  contain
                  src="@/assets/img/logoFooter.png"
                  eager
                  class="logo-lakeway-footer"
                ></v-img>
                <h3 class="uppercase mb-3 white--text">contact us</h3>

                <a
                  :href="homeSingleton.footer_address.link"
                  target="_blank"
                  class="white--text not-text-decoration d-block address-custom"
                >
                  <span class="d-inline white--text">{{
                    homeSingleton.footer_address.text
                  }}</span>
                </a>
                <div class="phone-margin-custom d-block d-md-inline-block">
                  <span class="small-texts white--text" style="font-weight: 750"
                    >Phone
                  </span>
                  <a
                    :href="'tel:' + homeSingleton.footer_phone.phone_no_format"
                    target="_blank"
                    class="white--text not-text-decoration"
                  >
                    <span class="d-inline white--text"
                      >{{ homeSingleton.footer_phone.phone_format }}
                    </span>
                  </a>
                </div>
                <div class="mt-1 mt-sm-0 d-block d-md-inline-block">
                  <span
                    class="pl-md-3 small-texts white--text"
                    style="font-weight: 750"
                    >Email
                  </span>
                  <a
                    :href="'mailto:' + homeSingleton.footer_email"
                    target="_blank"
                    class="white--text not-text-decoration"
                  >
                    <span class="d-inline white--text"
                      >{{ homeSingleton.footer_email }}
                    </span>
                  </a>
                </div>

                <v-row
                  no-gutters
                  class="margin-custom-1635"
                  id="socialMedia"
                  justify="center"
                >
                  <v-col cols="auto" class="column-btn">
                    <v-btn
                      text
                      icon
                      :href="homeSingleton.instagram"
                      target="_blank"
                      :ripple="false"
                      class="px-0"
                    >
                      <v-icon class="icon-social-media">mdi-instagram</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="column-btn">
                    <v-btn
                      text
                      icon
                      :href="homeSingleton.facebook"
                      target="_blank"
                      :ripple="false"
                    >
                      <v-icon class="icon-social-media">mdi-facebook</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="column-btn">
                    <v-btn
                      text
                      icon
                      :href="homeSingleton.twitter"
                      target="_blank"
                      :ripple="false"
                    >
                      <v-icon class="icon-social-media">mdi-twitter</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="column-btn">
                    <v-btn
                      text
                      icon
                      :href="homeSingleton.pinterest"
                      target="_blank"
                      :ripple="false"
                    >
                      <v-icon class="icon-social-media">mdi-pinterest</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="div-all-rights">
                  <span class="white--text"
                    >© {{ date }}, LAKEWAY FOODS.
                    <br v-if="$vuetify.breakpoint.smAndDown" />
                    All Rights Reserved.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </template>
      <!-- /contenido mobile -->

      <!-- contenido tablet vertical hacia arriba -->
      <template v-else>
        <template v-if="$vuetify.breakpoint.sm">
          <v-img
            :src="require('@/assets/img/footer-ipad.png')"
            class="img-footer-new"
            contain
            position="center bottom"
          >
            <v-container class="fill-height">
              <v-row
                class="white--text position-relative footer-row text-center"
                align="center"
                justify="center"
              >
                <v-col cols="12" sm="auto">
                  <v-img
                    contain
                    src="@/assets/img/logoFooter.png"
                    eager
                    class="logo-lakeway-footer"
                  ></v-img>
                  <h3 class="uppercase mb-3 white--text">contact us</h3>

                  <a
                    :href="homeSingleton.footer_address.link"
                    target="_blank"
                    class="
                      white--text
                      not-text-decoration
                      d-block
                      address-custom
                    "
                  >
                    <span class="d-inline white--text">{{
                      homeSingleton.footer_address.text
                    }}</span>
                  </a>
                  <div class="phone-margin-custom d-block d-md-inline-block">
                    <span
                      class="small-texts white--text"
                      style="font-weight: 750"
                      >Phone
                    </span>
                    <a
                      :href="
                        'tel:' + homeSingleton.footer_phone.phone_no_format
                      "
                      target="_blank"
                      class="white--text not-text-decoration"
                    >
                      <span class="d-inline white--text"
                        >{{ homeSingleton.footer_phone.phone_format }}
                      </span>
                    </a>
                  </div>
                  <div class="mt-1 mt-sm-0 d-block d-md-inline-block">
                    <span
                      class="pl-md-3 small-texts white--text"
                      style="font-weight: 750"
                      >Email
                    </span>
                    <a
                      :href="'mailto:' + homeSingleton.footer_email"
                      target="_blank"
                      class="white--text not-text-decoration"
                    >
                      <span class="d-inline white--text"
                        >{{ homeSingleton.footer_email }}
                      </span>
                    </a>
                  </div>

                  <v-row
                    no-gutters
                    class="margin-custom-1635"
                    id="socialMedia"
                    justify="center"
                  >
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.instagram"
                        target="_blank"
                        :ripple="false"
                        class="px-0"
                      >
                        <v-icon class="icon-social-media">mdi-instagram</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.facebook"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-facebook</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.twitter"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-twitter</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.pinterest"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-pinterest</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div class="div-all-rights">
                    <span class="white--text"
                      >© {{ date }}, LAKEWAY FOODS. All Rights Reserved.</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </template>

        <template v-else>
          <v-img
            :src="require('@/assets/img/FOOTER-LAKEWAY.png')"
            class="img-footer-new"
            contain
            position="center bottom"
          >
            <v-container class="fill-height">
              <v-row
                class="white--text position-relative footer-row text-center"
                align="center"
                justify="center"
              >
                <v-col cols="12" sm="auto">
                  <v-img
                    contain
                    src="@/assets/img/logoFooter.png"
                    eager
                    class="logo-lakeway-footer"
                  ></v-img>
                  <h3 class="uppercase mb-3 white--text">contact us</h3>

                  <a
                    :href="homeSingleton.footer_address.link"
                    target="_blank"
                    class="
                      white--text
                      not-text-decoration
                      d-block
                      address-custom
                    "
                  >
                    <span class="d-inline white--text">{{
                      homeSingleton.footer_address.text
                    }}</span>
                  </a>
                  <div class="phone-margin-custom d-block d-md-inline-block">
                    <span
                      class="small-texts white--text"
                      style="font-weight: 750"
                      >Phone
                    </span>
                    <a
                      :href="
                        'tel:' + homeSingleton.footer_phone.phone_no_format
                      "
                      target="_blank"
                      class="white--text not-text-decoration"
                    >
                      <span class="d-inline white--text"
                        >{{ homeSingleton.footer_phone.phone_format }}
                      </span>
                    </a>
                  </div>
                  <div class="mt-1 mt-sm-0 d-block d-md-inline-block">
                    <span
                      class="pl-md-3 small-texts white--text"
                      style="font-weight: 750"
                      >Email
                    </span>
                    <a
                      :href="'mailto:' + homeSingleton.footer_email"
                      target="_blank"
                      class="white--text not-text-decoration"
                    >
                      <span class="d-inline white--text"
                        >{{ homeSingleton.footer_email }}
                      </span>
                    </a>
                  </div>

                  <v-row
                    no-gutters
                    class="margin-custom-1635"
                    id="socialMedia"
                    justify="center"
                  >
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.instagram"
                        target="_blank"
                        :ripple="false"
                        class="px-0"
                      >
                        <v-icon class="icon-social-media">mdi-instagram</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.facebook"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-facebook</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.twitter"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-twitter</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="column-btn">
                      <v-btn
                        text
                        icon
                        :href="homeSingleton.pinterest"
                        target="_blank"
                        :ripple="false"
                      >
                        <v-icon class="icon-social-media">mdi-pinterest</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div class="div-all-rights">
                    <span class="white--text"
                      >© {{ date }}, LAKEWAY FOODS. All Rights Reserved.</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </template>
      </template>
      <!-- /contenido tablet vertical hacia arriba -->
    </v-footer>
    
    <div v-else class="div-footer-desk">
      <div style="width: 100vw;">
        <v-img :src="require('../../assets/img/verdes.png')" width="100%" height="100%" contain style="transform: translate(-14px, 2px);"></v-img>
      </div>
      <!-- <div class="texture-green"></div> -->
      <v-footer class="footer-new-original" color="#003D11">
        <v-container fluid>
          <v-row
            class="white--text position-relative footer-row text-center"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" sm="auto">
              <v-img
                contain
                src="@/assets/img/logoFooter.png"
                eager
                class="logo-lakeway-footer"
              ></v-img>
              <h3 class="uppercase mb-3 white--text">contact us</h3>
      
              <a
                :href="homeSingleton.footer_address.link"
                target="_blank"
                class="white--text not-text-decoration d-block address-custom"
              >
                <span class="d-inline white--text">{{
                  homeSingleton.footer_address.text
                }}</span>
              </a>
              <div class="phone-margin-custom d-block d-md-inline-block">
                <span class="small-texts white--text" style="font-weight: 750"
                  >Phone
                </span>
                <a
                  :href="'tel:' + homeSingleton.footer_phone.phone_no_format"
                  target="_blank"
                  class="white--text not-text-decoration"
                >
                  <span class="d-inline white--text"
                    >{{ homeSingleton.footer_phone.phone_format }}
                  </span>
                </a>
              </div>
              <div class="mt-1 mt-sm-0 d-block d-md-inline-block">
                <span
                  class="pl-md-3 small-texts white--text"
                  style="font-weight: 750"
                  >Email
                </span>
                <a
                  :href="'mailto:' + homeSingleton.footer_email"
                  target="_blank"
                  class="white--text not-text-decoration"
                >
                  <span class="d-inline white--text"
                    >{{ homeSingleton.footer_email }}
                  </span>
                </a>
              </div>
      
              <v-row
                no-gutters
                class="margin-custom-1635"
                id="socialMedia"
                justify="center"
              >
                <v-col cols="auto" class="column-btn">
                  <v-btn
                    text
                    icon
                    :href="homeSingleton.instagram"
                    target="_blank"
                    :ripple="false"
                    class="px-0"
                  >
                    <v-icon class="icon-social-media">mdi-instagram</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="column-btn">
                  <v-btn
                    text
                    icon
                    :href="homeSingleton.facebook"
                    target="_blank"
                    :ripple="false"
                  >
                    <v-icon class="icon-social-media">mdi-facebook</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="column-btn">
                  <v-btn
                    text
                    icon
                    :href="homeSingleton.twitter"
                    target="_blank"
                    :ripple="false"
                  >
                    <v-icon class="icon-social-media">mdi-twitter</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="column-btn">
                  <v-btn
                    text
                    icon
                    :href="homeSingleton.pinterest"
                    target="_blank"
                    :ripple="false"
                  >
                    <v-icon class="icon-social-media">mdi-pinterest</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
      
              <div class="div-all-rights">
                <span class="white--text"
                  >© {{ date }}, LAKEWAY FOODS. All Rights Reserved.</span
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>

  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    date() {
      var dateToFormat = new Date();
      var year = dateToFormat.getFullYear();

      return year;
    },
  }, //end computed
  data() {
    return {
      homeSingleton: {
        footer_address: {
          text: "",
          link: "",
        },
        footer_phone: {
          phone_format: "",
          phone_no_format: "",
        },
        footer_email: "",
      },
      itemsContact: [
        {
          name: "Phone",
          value: "512 998 7876",
        },
        {
          name: "Email",
          value: "contacto@lakeway.com",
        },
        {
          name: "Address",
          value: "AUSTIN,TX 78746",
        },
      ],
      reRender: true,
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.homeSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/home?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data.collectionHome));
  },
  mounted() {
    window.addEventListener("resize", this.resizeM);
  },
  methods: {
    resizeM: function () {
      if (window.innerWidth < 600) {
        this.reRender = false;
        setTimeout(() => {
          this.reRender = true;
        }, 100);
      } else {
        this.reRender = false;
        setTimeout(() => {
          this.reRender = true;
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;
// .Footer{
//   // height: 500px;
// }

.footer {
  height: auto;
}

.logo-lakeway-footer {
  margin: auto;
  max-width: 400px;
  margin-bottom: 46px;
  
  @media screen and(max-width: $xxl) {
    margin-bottom: 20px;
    max-width: 330px;
  }

  @media screen and(max-width: $xl) {
    max-width: 250px;
  }

  @media screen and(max-width: $md) {
    max-width: 200px;
    margin-top: 45px;
  }

  @media screen and(max-width: $sm) {
    margin-top: 40px;
  }
}

.div-all-rights {
  margin: 20px 0px 46px 0px;
  // margin: 50px 0px 40px 0px;

  @media screen and(max-width: $xl) {
    // margin: 30px 0px 20px 0px;
    margin: 10px 0px 46px 0px;
  }

  @media screen and(max-width: $md) {
    // margin: 30px 0px 20px 0px;
    margin: 10px 0px 30px 0px;
  }

  @media screen and(max-width: $sm) {
    // max-width: 205px;
    // margin: 30px 0px 0px 0px;
    margin: 10px 0px 36px 0px;
  }
}

.footer {
  padding: 0 !important;
  // padding-right: 0 !important;
  // padding: 80px 0px !important;

  // @media screen and(max-width: $xl) {
  //   padding: 50px 0px !important;
  // }
}

.first-column-footer {
  padding: 0 90px;

  @media screen and(max-width: $sm) {
    padding: 0 58px;
  }
}

.column-btn {
  margin-right: 40px;

  @media screen and(max-width: $xxl) {
    margin-right: 20px;
  }

  @media screen and(max-width: $xl) {
    margin-right: 10px;
  }
}

.icon-social-media {
  font-size: 53px !important;
  color: white !important;
  @media screen and(max-width: $xxl) {
    font-size: 36px !important;
  }

  @media screen and(max-width: $xl) {
    font-size: 26px !important;
  }
}

.footer-inside {
  min-height: 480px;

  @media screen and(max-width: $xl) {
    min-height: 350px;
  }

  @media screen and(max-width: $md) {
    min-height: 370px;
  }

  @media screen and(max-width: $sm) {
    min-height: 420px;
  }
}

.margin-custom-1635 {
  margin-bottom: 60px;
  margin-top: 60px;

  @media screen and(max-width: $xxl) {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  @media screen and(max-width: $xl) {
    margin-top: 30px;
  }
  @media screen and(max-width: $lg) {
    margin-bottom: 30px;
  }

  @media screen and(max-width: $md) {
    margin-top: 20px;
  }

  @media screen and(max-width: $sm) {
    margin-top: 40px;
  }
}

.address-custom {
  @media screen and(max-width: $md) {
    max-width: 100%;
    line-height: 1.5;
    margin: auto;
  }

  @media screen and(max-width: $sm) {
    max-width: 190px;
    line-height: 1.5;
  }
}

.phone-margin-custom {
  margin-top: 10px;
  @media screen and(max-width: $lg) {
    margin-top: 6px;
  }
  @media screen and(max-width: $sm) {
    margin-top: 2px;
  }
}

.footer-new-original {
  min-height: 700px;

  @media screen and(max-width: $xxl) {
    min-height: 500px;
  }

  @media screen and(max-width: $xl) {
    min-height: 450px;
  }

  &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/blanco-izq.png);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    left: 0;
    pointer-events: none;
  }


  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/blanco-derecho.png);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    right: 0;
    pointer-events: none;
  }
}

.footer-new {
  // min-height: 100vh;
  position: relative;
  padding: 0px !important;
  overflow: hidden;
  // &::before{
  //   content: "";
  //   position:absolute;
  //   background: url(../../assets/img/FOOTER-LAKEWAY.png);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position-y: bottom;
  //   width: 100vw;
  //   height: 100%;
  // }
}
.footer-row {
  // height: 100%;
  // // margin-top: 150px;
  // transform: translateY(25%);

  // @media screen and(max-width: $xl) {
  //   transform: translateY(25%);
  // }
  // @media screen and(max-width: $md) {
  //   transform: translateY(0);
  // }
}

.img-footer-new {
  @media screen and(max-width: $lg) {
    height: 500px;
  }

  @media screen and(max-width: $md) {
    height: 550px;
  }

  @media screen and(max-width: $sm) {
    height: auto;
  }
}

.text-contact {
  font-size: 45px;
}

.div-footer-desk{
  // width: 100%;
  @media screen and(max-width: $xl) {
    // width: 100vw;
  }
}

.texture-green{
  height: 150px;
  background-image: url(../../assets/img/verdes.png);
  background-size: contain;
}
</style>