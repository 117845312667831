import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Products from '../views/Products.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/product/:category?/:subcategory?/:url',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: () => import('../views/Recipes.vue')
  },
  {
    path: '/our-story',
    name: 'Our Story',
    component: () => import('../views/OurStory.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/Careers.vue')
  },
  {
    path: '/recipe/:url',
    name: 'Recipe',
    component: () => import('../views/Recipe.vue')
  },
  {
    path: '/CommunityImpact',
    name: 'CommunityImpact',
    component: () => import('../views/CommunityImpact.vue')
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import('../views/Safety.vue')
  }
]

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
