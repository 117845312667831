<template>
  <div class="Header">
    <!-- alto en 2k = 150 cuando se hace pequeño 130-->
    <v-app-bar
      class="header"
      app
      light
      elevation="0"
      :color="color"
      :dark="!barCollapsed"
      :height="$vuetify.breakpoint.smAndUp ? barHeight : 80"
    >
      <!-- :hide-on-scroll="hideBar" -->
      <v-container fluid>
        <v-row align="center" justify="space-between">
          <v-col
          v-if="$vuetify.breakpoint.width > 1162"
            class="text-right justify-end"
            align-self="center"
          >
            <v-row
              class="mr-15"
              align="center"
              no-gutters
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-col
                cols="4"
                class="text-center"
                v-if="storySingleton.show_section"
              >
                <router-link
                  to="/our-story"
                  class="not-text-decoration header__items"
                >
                  <span class="header__items--span bold black--text">
                    our story
                  </span>
                </router-link>
              </v-col>

              <v-col cols="4" class="text-center">
                <div
                  style="height: 100px"
                  class="d-flex align-center text-center position-relative"
                >
                  <span
                    class="
                      header__items--span
                      bold
                      mx-auto
                      text-center
                      black--text
                    "
                    style="cursor: pointer;"
                    @click="menuDesk = !menuDesk"
                  >
                    PRODUCTS
                    <v-icon
                      class="menu-arrow-products"
                      :class="{ 'rotate-icon': menuDesk }"
                      >mdi-chevron-down</v-icon
                    >
                  </span>
                </div>
              </v-col>

              <v-col
                cols="4"
                class="text-center"
                v-if="contactSingleton.show_section"
              >
                <router-link
                  to="/contact"
                  class="not-text-decoration header__items"
                >
                  <span class="header__items--span bold black--text">
                    contact us
                  </span>
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="auto" v-if="$vuetify.breakpoint.width > 599"> </v-col>

          <v-col cols="auto" class="px-0 margin-in-logo">
            <div class="mx-auto">
              <router-link to="/">
                <v-img
                  contain
                  src="@/assets/icons/lake-logo.svg"
                  class="header__logo mx-auto"
                  v-bind:class="{
                    minimum: !barCollapsed && $vuetify.breakpoint.mdAndUp,
                  }"
                >
                </v-img>
              </router-link>
            </div>
          </v-col>

          <v-col align-self="center" class="px-0 text-left" v-if="$vuetify.breakpoint.width > 1162">
            <v-row class="ml-15" align="center" no-gutters>
              <v-col
                cols="4"
                class="text-center"
                v-if="carrersSingleton.show_section"
              >
                <router-link
                  to="/careers"
                  class="not-text-decoration header__items"
                >
                  <span class="header__items--span bold black--text">
                    careers
                  </span>
                </router-link>
              </v-col>

              <v-col
                cols="4"
                class="text-center"
                v-if="safetySingleton.show_section"
              >
                <router-link
                  to="/safety"
                  class="not-text-decoration header__items"
                >
                  <span class="header__items--span bold black--text">
                    safety
                  </span>
                </router-link>
              </v-col>

              <v-col
                cols="4"
                class="text-center"
                v-if="communitySingleton.show_section"
              >
                <router-link
                  to="/CommunityImpact"
                  class="not-text-decoration header__items"
                >
                  <span class="header__items--span bold black--text">
                    community impact
                  </span>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <!-- btn mobile -->
          <v-col cols="auto" v-if="$vuetify.breakpoint.width < 1162" class="text-right" align-self="center">
            <v-btn icon :ripple="false" @click="showMenu = !showMenu">
              <v-icon size="50" style="color: #1b483c"
                ><template v-if="!showMenu"> mdi-menu</template>
                <template v-else>mdi-close</template></v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- menu desk -->
    <v-expand-transition>
      <div
        v-if="menuDesk && $vuetify.breakpoint.width > 1162"
        v-bind:class="{ 'modify-position': !barCollapsed }"
        class="menu-complete"
      >
        <!-- @mouseleave="menuDesk = false" -->
        <div class="background-menu">
          <div class="menu-content">
            <v-row class="fill-height position-relative" justify="center">
              <v-col cols="12" class="text-center position-relative">
                <v-img
                  :src="require('@/assets/icons/close-lake.svg')"
                  contain
                  class="close-lake-menu"
                  @click="menuDesk = false"
                  style="cursor: pointer"
                ></v-img>
                <h1 class="menu-title">
                  Produce fresh
                  <span class="pinyon pl-2 pl-xl-5">from the Farm</span>
                </h1>
              </v-col>
              <v-col
                md="auto"
                v-for="(category, c) in productsF"
                v-bind:key="c"
                v-show="category.name !== 'Organics'"
                class="subcategory-column"
              >
                <template v-if="category.show_hide">
                  <div class="small-carousel">
                    <hooper
                      v-bind:ref="'carouselMiniatures' + c"
                      v-bind:settings="hooperSettings"
                    >
                      <template v-for="(product2, p2) in category.elements">
                        <slide
                          v-bind:key="p2 + '-6598188'"
                          v-if="
                            product2.path !== null && product2.type === 'product'
                          "
                        >
                          <img
                            v-bind:src="
                              'https://www.lakewaycms.actstudio.xyz' +
                              product2.path
                            "
                            alt=""
                            class="miniature-carousel"
                          />
                        </slide>
                      </template>
                    </hooper>
                  </div>
                  <template>
                    <span
                      class="d-block category-name-header markazi"
                      style="font-weight: bold"
                      >{{ category.name }}</span
                    >
                    <template v-for="(element, s) in category.elements">
                      <span
                        v-if="element.type === 'subcategoryF'"
                        v-bind:key="s"
                        class="
                          d-block
                          subcategory-name-header
                          color-green-subcategory
                        "
                        style="font-weight: bold"
                        >{{ element.name }}</span
                      >
                  
                      <router-link
                        v-if="element.type === 'product'"
                        v-bind:key="c + '-1' + s + '-1'"
                        v-bind:to="'/product/'+ category.url + '/' + element.url"
                        class="not-text-decoration black--text product-name-menu"
                        v-bind:class="{
                          'current-position': element.url === $route.params.url,
                        }"
                      >
                        <span
                          class="d-block bold"
                          @mouseover="changeSlider(c, element.index)"
                          >{{ element.name }}</span
                        >
                      </router-link>
                    </template>
                  </template>
                </template>
              </v-col>

              <!-- organics -->
              <v-col cols="12" class="pt-0 pt-xl-3 mt-xl-1">
                <v-row no-gutters justify="center">
                  <template v-for="(category, c) in categoriesP">
                    <v-col
                      cols="12"
                      v-bind:key="c"
                      class="subcategory-column"
                      v-if="category.name === 'Organics' && category.show_hide"
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h1
                            class="
                              menu-title
                              title-organics
                              pinyon
                              text-center
                              bold
                            "
                          >
                            {{ category.name }}
                          </h1>
                        </v-col>
                        <v-col cols="12" class="pt-7">
                          <template v-if="category.name === 'Organics'">
                            <v-row no-gutters justify="center">
                              <v-col
                                v-for="(
                                  subcategory, s
                                ) in category.subcategories"
                                v-bind:key="s"
                                cols="12"
                                sm="auto"
                                class="subcategory-column"
                              >
                                <div
                                  class="subcategory-organics small-carousel"
                                >
                                  <v-img
                                    :src="
                                      require('@/assets/img/leaf-organics.png')
                                    "
                                    contain
                                    class="img-leaf"
                                  ></v-img>
                                  <hooper
                                    :ref="'carouselMiniaturesOrganics' + s"
                                    v-bind:settings="hooperSettings"
                                  >
                                    <template v-if="category.products !== null">
                                      <slide
                                        v-for="(
                                          product2, p2
                                        ) in category.products"
                                        v-bind:key="p2 + '-6598188'"
                                      >
                                        <template
                                          v-if="
                                            product2.intro_miniature !== null
                                          "
                                        >
                                          <img
                                            v-bind:src="
                                              'https://www.lakewaycms.actstudio.xyz' +
                                              product2.intro_miniature.path
                                            "
                                            alt=""
                                            class="miniature-carousel"
                                          />
                                        </template>
                                      </slide>
                                    </template>
                                    <slide
                                      v-for="(
                                        product, p
                                      ) in subcategory.products"
                                      v-bind:key="p + '-626851 ' + s + '-06035'"
                                    >
                                      <template
                                        v-if="product.intro_miniature !== null"
                                      >
                                        <img
                                          v-bind:src="
                                            'https://www.lakewaycms.actstudio.xyz' +
                                            product.intro_miniature.path
                                          "
                                          alt=""
                                          class="miniature-carousel"
                                        />
                                      </template>
                                    </slide>
                                  </hooper>
                                </div>
                                <v-row no-gutters justify="center">
                                  <v-col cols="auto">
                                    <span
                                      :key="s"
                                      class="
                                        d-block
                                        category-name-header
                                        markazi
                                        mb-6
                                      "
                                      style="font-weight: bold"
                                      >{{ subcategory.name }}</span
                                    >
                                    <template
                                      v-for="(
                                        product2, p2
                                      ) in category.products"
                                    >
                                      <div :key="c + '-2' + p2 + '-2'">
                                        <router-link
                                          v-bind:to="'/product/'+ category.url + '/' + product2.url"
                                          class="
                                            not-text-decoration
                                            black--text
                                            product-name-menu-no-sub
                                          "
                                        >
                                          <span
                                            class="d-block bold"
                                            @mouseover="
                                              changeSliderOrganics(s, p2)
                                            "
                                            >{{ product2.name }}</span
                                          >
                                        </router-link>
                                      </div>
                                    </template>
                                    <template
                                      v-for="(
                                        product, p
                                      ) in subcategory.products"
                                    >
                                      <router-link
                                        v-bind:key="c + '-1' + s + '-1' + p + '-1'"
                                        v-bind:to="'/product/'+ category.url + '/' + product.url"
                                        class="
                                          not-text-decoration
                                          black--text
                                          product-name-menu
                                        "
                                      >
                                        <span
                                          class="d-block bold"
                                          @mouseover="
                                            changeSliderOrganics(s, p)
                                          "
                                          >{{ product.name }}</span
                                        >
                                      </router-link>
                                    </template>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-expand-transition>

    <!-- menú mobile -->
    <v-expand-transition>
      <div
        class="MenuMobile"
        v-bind:class="{ open: showMenu }"
        v-if="showMenu && $vuetify.breakpoint.width < 1162"
      >
        <v-container fluid style="height: 100vh; overflow-y: auto">
          <v-row class="fill-height">
            <v-col class="pa-0">
              <v-row no-gutters>

                <template v-for="(item, i) in navigationItems">
                  <template v-if="i == 1">
                    <v-col cols="12" :key="i">
                      <div
                        class="d-flex align-center element-padding"
                        @click="productsMenu = !productsMenu"
                      >
                        <span class="header__items--span bold products-item">
                          <span>PRODUCTS</span>

                          <v-icon class="menu-arrow-products">
                            <template v-if="!productsMenu">
                              mdi-chevron-down
                            </template>
                            <template v-else> mdi-close </template>
                          </v-icon>
                        </span>
                      </div>

                      <v-expand-transition>
                        <div
                          id="productsMobile"
                          style="background-color: #f7f4eb"
                          v-if="productsMenu"
                        >
                          <v-img
                            :src="require('@/assets/img/menu-back-mobile.png')"
                            contain
                            class="cover-back-mobile"
                          >
                            <v-container fluid class="pb-12 back-mobile">
                              <v-row>
                                <v-col cols="12" class="pa-0">
                                  <v-row no-gutters>
                                    <v-col class="text-center">
                                      <h1
                                        class="
                                          menu-title
                                          text-intro-menu-mobile
                                        "
                                      >
                                        Produce fresh
                                        <br v-if="$vuetify.breakpoint.xs" />
                                        <span class="pinyon pl-5"
                                          >from the Farm</span
                                        >
                                      </h1>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-col
                                      cols="12"
                                      md="auto"
                                      v-for="(category, c) in categoriesP"
                                      v-bind:key="c"
                                      class="subcategory-column"
                                    >
                                      <template
                                        v-if="category.name !== 'Organics'"
                                      >
                                        <span
                                          class="
                                            d-block
                                            category-name-header
                                            markazi
                                          "
                                          style="font-weight: bold"
                                          >{{ category.name }}

                                          <div
                                            class="small-carousel"
                                            v-if="category.name !== 'Organics'"
                                          >
                                            <hooper
                                              ref="carouselMiniatures"
                                              v-bind:settings="hooperSettings"
                                            >
                                              <template
                                                v-if="
                                                  category.products !== null
                                                "
                                              >
                                                <slide
                                                  v-for="(
                                                    product2, p2
                                                  ) in category.products"
                                                  v-bind:key="p2 + '-6598188'"
                                                >
                                                  <template
                                                    v-if="
                                                      product2.intro_miniature !==
                                                      null
                                                    "
                                                  >
                                                    <img
                                                      v-bind:src="
                                                        'https://www.lakewaycms.actstudio.xyz' +
                                                        product2.intro_miniature
                                                          .path
                                                      "
                                                      alt=""
                                                      class="miniature-carousel"
                                                    />
                                                  </template>
                                                </slide>
                                              </template>

                                              <template
                                                v-for="(
                                                  subcategory, s
                                                ) in category.subcategories"
                                              >
                                                <template>
                                                  <slide
                                                    v-for="(
                                                      product, p
                                                    ) in subcategory.products"
                                                    v-bind:key="
                                                      p +
                                                      '-626851 ' +
                                                      s +
                                                      '-06035'
                                                    "
                                                  >
                                                    <template
                                                      v-if="
                                                        product.intro_miniature !==
                                                        null
                                                      "
                                                    >
                                                      <img
                                                        v-bind:src="
                                                          'https://www.lakewaycms.actstudio.xyz' +
                                                          product
                                                            .intro_miniature
                                                            .path
                                                        "
                                                        alt=""
                                                        class="
                                                          miniature-carousel
                                                        "
                                                      />
                                                    </template>
                                                  </slide>
                                                </template>
                                              </template>
                                            </hooper>
                                          </div>
                                        </span>

                                        <template
                                          v-for="(
                                            product2, p2
                                          ) in category.products"
                                        >
                                          <div :key="c + '-2' + p2 + '-2'">
                                            <router-link
                                              v-bind:to="'/product/'+ category.url + '/' + product2.url"
                                              class="
                                                not-text-decoration
                                                black--text
                                                product-name-menu-no-sub
                                              "
                                            >
                                              <span
                                                class="d-block bold"
                                                @mouseover="
                                                  (changeCarouselC = c),
                                                    (changeCarouselP = p2)
                                                "
                                                >{{ product2.name }}</span
                                              >
                                            </router-link>
                                          </div>
                                        </template>

                                        <template
                                          v-for="(
                                            subcategory, s
                                          ) in category.subcategories"
                                        >
                                          <span
                                            :key="s"
                                            class="
                                              d-block
                                              subcategory-name-header
                                              color-green-subcategory
                                              text-center
                                            "
                                            style="font-weight: bold"
                                            >{{ subcategory.name }}</span
                                          >

                                          <template
                                            v-for="(
                                              product, p
                                            ) in subcategory.products"
                                          >
                                            <router-link
                                              v-bind:key="
                                                c + '-1' + s + '-1' + p + '-1'
                                              "
                                              v-bind:to="'/product/'+ category.url + '/' + product.url"
                                              class="
                                                not-text-decoration
                                                black--text
                                                product-name-menu
                                              "
                                            >
                                              <span
                                                class="d-block bold"
                                                @mouseover="
                                                  (changeCarouselC = c),
                                                    (changeCarouselP = p)
                                                "
                                                >{{ product.name }}</span
                                              >
                                            </router-link>
                                          </template>
                                        </template>
                                      </template>
                                    </v-col>

                                    <!-- organics -->
                                    <v-col
                                      cols="12"
                                      class="organics-col-mobile"
                                    >
                                      <v-row no-gutters justify="center">
                                        <v-col
                                          cols="12"
                                          v-for="(category, c) in categoriesP"
                                          v-bind:key="c"
                                          class="subcategory-column"
                                          v-show="category.name === 'Organics'"
                                        >
                                          <v-row no-gutters>
                                            <v-col cols="12">
                                              <h1
                                                class="
                                                  menu-title
                                                  pinyon
                                                  text-center
                                                  organics-title-mobile
                                                "
                                              >
                                                {{ category.name }}
                                              </h1>
                                              <template
                                                v-if="
                                                  category.name === 'Organics'
                                                "
                                              >
                                                <v-row
                                                  no-gutters
                                                  justify="center"
                                                >
                                                  <v-col
                                                    v-for="(
                                                      subcategory, s
                                                    ) in category.subcategories"
                                                    v-bind:key="s"
                                                    cols="12"
                                                    class="subcategory-column"
                                                  >
                                                    <v-row
                                                      no-gutters
                                                      justify="center"
                                                    >
                                                      <v-col cols="12">
                                                        <span
                                                          :key="s"
                                                          class="
                                                            d-block
                                                            category-name-header
                                                            markazi
                                                            mb-6
                                                          "
                                                          style="
                                                            font-weight: bold;
                                                          "
                                                          >{{
                                                            subcategory.name
                                                          }}

                                                          <div
                                                            class="
                                                              subcategory-organics
                                                              small-carousel
                                                            "
                                                          >
                                                            <v-img
                                                              :src="
                                                                require('@/assets/img/leaf-organics.png')
                                                              "
                                                              contain
                                                              class="img-leaf"
                                                            ></v-img>
                                                            <hooper
                                                              ref="carouselMiniatures"
                                                              v-bind:settings="
                                                                hooperSettings
                                                              "
                                                            >
                                                              <template
                                                                v-if="
                                                                  category.products !==
                                                                  null
                                                                "
                                                              >
                                                                <slide
                                                                  v-for="(
                                                                    product2, p2
                                                                  ) in category.products"
                                                                  v-bind:key="
                                                                    p2 +
                                                                    '-6598188'
                                                                  "
                                                                >
                                                                  <template
                                                                    v-if="
                                                                      product2.intro_miniature !==
                                                                      null
                                                                    "
                                                                  >
                                                                    <img
                                                                      v-bind:src="
                                                                        'https://www.lakewaycms.actstudio.xyz' +
                                                                        product2
                                                                          .intro_miniature
                                                                          .path
                                                                      "
                                                                      alt=""
                                                                      class="
                                                                        miniature-carousel
                                                                      "
                                                                    />
                                                                  </template>
                                                                </slide>
                                                              </template>

                                                              <slide
                                                                v-for="(
                                                                  product, p
                                                                ) in subcategory.products"
                                                                v-bind:key="
                                                                  p +
                                                                  '-626851 ' +
                                                                  s +
                                                                  '-06035'
                                                                "
                                                              >
                                                                <template
                                                                  v-if="
                                                                    product.intro_miniature !==
                                                                    null
                                                                  "
                                                                >
                                                                  <img
                                                                    v-bind:src="
                                                                      'https://www.lakewaycms.actstudio.xyz' +
                                                                      product
                                                                        .intro_miniature
                                                                        .path
                                                                    "
                                                                    alt=""
                                                                    class="
                                                                      miniature-carousel
                                                                    "
                                                                  />
                                                                </template>
                                                              </slide>
                                                            </hooper>
                                                          </div>
                                                        </span>

                                                        <template
                                                          v-for="(
                                                            product2, p2
                                                          ) in category.products"
                                                        >
                                                          <div
                                                            :key="
                                                              c +
                                                              '-2' +
                                                              p2 +
                                                              '-2'
                                                            "
                                                          >
                                                            <router-link
                                                              v-bind:to="'/product/'+ category.url + '/' + product2.url"
                                                              class="
                                                                not-text-decoration
                                                                black--text
                                                                product-name-menu-no-sub
                                                              "
                                                            >
                                                              <span
                                                                class="d-block bold"
                                                                @mouseover="
                                                                  (changeCarouselC =
                                                                    c),
                                                                    (changeCarouselP =
                                                                      p2)
                                                                "
                                                                >{{
                                                                  product2.name
                                                                }}</span
                                                              >
                                                            </router-link>
                                                          </div>
                                                        </template>

                                                        <template
                                                          v-for="(
                                                            product, p
                                                          ) in subcategory.products"
                                                        >
                                                          <router-link
                                                            :key="
                                                              c +
                                                              '-1' +
                                                              s +
                                                              '-1' +
                                                              p +
                                                              '-1'
                                                            "
                                                            v-bind:to="'/product/'+ category.url + '/' + product.url"
                                                            class="
                                                              not-text-decoration
                                                              black--text
                                                              product-name-menu
                                                            "
                                                          >
                                                            <span
                                                              class="d-block bold"
                                                              @mouseover="
                                                                (changeCarouselC =
                                                                  c),
                                                                  (changeCarouselP =
                                                                    p)
                                                              "
                                                              >{{
                                                                product.name
                                                              }}</span
                                                            >
                                                          </router-link>
                                                        </template>
                                                      </v-col>
                                                    </v-row>
                                                  </v-col>
                                                </v-row>
                                              </template>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                        </div>
                      </v-expand-transition>
                    </v-col>
                  </template>
                </template>

                <v-col
                  cols="12"
                  v-bind:class="{ 'd-none': productsMenu }"
                  class="element-padding"
                  v-if="storySingleton.show_section"
                >
                  <router-link
                    to="/our-story"
                    class="not-text-decoration header__items"
                  >
                    <span class="header__items--span bold"> our story </span>
                  </router-link>
                </v-col>

                <v-col
                  cols="12"
                  v-bind:class="{ 'd-none': productsMenu }"
                  class="element-padding"
                  v-if="safetySingleton.show_section"
                >
                  <router-link
                    to="/safety"
                    class="not-text-decoration header__items"
                  >
                    <span class="header__items--span bold"> safety </span>
                  </router-link>
                </v-col>

                <v-col
                  cols="12"
                  v-bind:class="{ 'd-none': productsMenu }"
                  class="element-padding"
                  v-if="communitySingleton.show_section"
                >
                  <router-link
                    to="/CommunityImpact"
                    class="not-text-decoration header__items"
                  >
                    <span class="header__items--span bold">
                      community impact
                    </span>
                  </router-link>
                </v-col>

                <v-col
                  cols="12"
                  v-bind:class="{ 'd-none': productsMenu }"
                  class="element-padding"
                  v-if="carrersSingleton.show_section"
                >
                  <router-link
                    to="/careers"
                    class="not-text-decoration header__items"
                  >
                    <span class="header__items--span bold"> careers </span>
                  </router-link>
                </v-col>

                <v-col
                  cols="12"
                  v-bind:class="{ 'd-none': productsMenu }"
                  class="element-padding"
                  v-if="contactSingleton.show_section"
                >
                  <router-link
                    to="/contact"
                    class="not-text-decoration header__items"
                  >
                    <span class="header__items--span bold"> contact us </span>
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "Header",
  components: {
    Hooper,
    Slide,
  },
  computed: {
    hideBar: function () {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    nudgeBottomMenu: function () {
      if (this.barCollapsed) {
        return 100;
      } else {
        return 200;
      }
    },
    productsF: function () {
      var categoriesF = this.categoriesP;
      var arrayN = [];

      for (let i = 0; i < categoriesF.length; i++) {
        const element = categoriesF[i];
        let auxCategory = {
          name: element.name,
          url: element.url,
          elements: [],
          show_hide: element.show_hide
        };
        let n = 0;
        if (element.products !== null) {
          for (let x = 0; x < element.products.length; x++) {
            const element3 = element.products[x];
            let auxProduct = {
              name: element3.name,
              type: "product",
              path: element3.intro_miniature.path,
              url: element3.url,
              index: n,
            };
            n++;
            auxCategory.elements.push(auxProduct);
          }
        }

        if (element.subcategories !== null) {
          for (let j = 0; j < element.subcategories.length; j++) {
            const element2 = element.subcategories[j];
            let auxSubcategory = {
              name: element2.name,
              type: "subcategoryF",
              path: null,
              index: null,
            };
            auxCategory.elements.push(auxSubcategory);
            if (element2.products !== null) {
              for (let x = 0; x < element2.products.length; x++) {
                const element3 = element2.products[x];
                let auxProduct = {
                  name: element3.name,
                  type: "product",
                  path: element3.intro_miniature.path,
                  url: element3.url,
                  index: n,
                };
                n++;
                auxCategory.elements.push(auxProduct);
              }
            }
          }
        }

        arrayN.push(auxCategory);
      }

      return arrayN;
    },
  },
  data() {
    return {
      navigationItems: [
        {
          name: "Our Story",
          url: "/our-story",
        },
        {
          name: "Products",
          url: "/products",
        },
        {
          name: "Contact us",
          url: "/contact",
        },
        {
          name: "Careers",
          url: "/careers",
        },
        {
          name: "Safety",
          url: "/safety",
        },
        {
          name: "Community Impact",
          url: "/CommunityImpact",
        },
      ],
      logoHeight: 286,
      showMenu: false,
      scroll: 0,
      color: "white",
      barCollapsed: true,
      barHeight: 100,
      products: [],
      categoriesP: [],
      subcategoriesP: [],
      render: false,
      productsMenu: false,
      changeCarouselC: 0,
      changeCarouselP: 0,
      changeCarousel: 0,
      menuDesk: false,
      hooperSettings: {
        autoPlay: true,
        itemsToShow: 1,
        playSpeed: 4000,
        infiniteScroll: true,
        mouseDrag: true,
        wheelControl: false,
        transition: 0,
      },
      stopCarrusel: false,
      storySingleton: {},
      contactSingleton: {},
      carrersSingleton: {},
      safetySingleton: {},
      communitySingleton: {},
    };
  },
  watch: {
    $route: function () {
      this.showMenu = false;
      this.menuDesk = false;
    },
    scroll: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.scroll < 250) {
          this.color = "white";
          this.barCollapsed = true;

          if (this.$vuetify.breakpoint.lgAndDown) {
            this.barHeight = 80;
          }
          else if(this.$vuetify.breakpoint.width > 2299) {
            this.barHeight = 150;
          }
          else {
            this.barHeight = 100;
          }
        } else {
          this.barCollapsed = false;
           if(this.$vuetify.breakpoint.width > 2299) {
             this.barHeight = 130;
          } else {
             this.barHeight = 60;
          }
        }
      }
    },
    showMenu: function () {
      var root = document.documentElement;
      if (this.showMenu) {
        root.classList.add("overflow-y-hidden");
      } else {
        root.classList.remove("overflow-y-hidden");
      }
    },
    // changeCarousel() {
    //   this.$refs.carouselMiniatures+this.changeCarousel.slideTo(this.changeCarousel);
    // },
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.storySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/story?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.storySingleton = data.content));

    this.contactSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/contact?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data.content));

    this.carrersSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/carrers?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.carrersSingleton = data.content));

    this.safetySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/safety?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.safetySingleton = data.content));

    this.communitySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/community?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.communitySingleton = res.community))
      .catch((error) => console.log(error.message));
  },
  beforeMount() {
    fetch("https://www.lakewaycms.actstudio.xyz/api/collections/get/Products", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: {},
        populate: 1, // resolve linked collection items,
        sort: { _o: -1 },
      }),
    })
      .then((res) => res.json())
      .then((res) => (this.products = res.entries))
      .catch((error) => console.log(error.message));

    fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/categoriesProducts",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sort: { _o: 1 },
          populate: 3,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesP = res.entries))
      .catch((error) => console.log(error.message));

    fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/subcategoriesProducts",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.subcategoriesP = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted: function () {
    window.addEventListener("scroll", this.changeOnScroll);

    // setInterval(() => {
    //   this.changeCarouselC++;
    //   if (this.changeCarouselC >= this.categoriesP.length - 1) {
    //     this.changeCarouselC = 0;
    //   }
    //   // this.changeCarouselP++
    // }, 2000);

    if (this.$vuetify.breakpoint.lgAndDown) {
      this.barHeight = 80;
    }
    else if(this.$vuetify.breakpoint.width > 2299) {
      this.barHeight = 150;
    }
    else {
      this.barHeight = 100;
    }

    window.addEventListener("resize", this.changeHeight);
  },
  methods: {
    changeSlider: function (slider, index) {
      if (slider == 0) {
        this.$refs.carouselMiniatures0[0].slideTo(parseInt(index));
      }
      if (slider == 1) {
        this.$refs.carouselMiniatures1[0].slideTo(parseInt(index));
      }
      if (slider == 2) {
        this.$refs.carouselMiniatures2[0].slideTo(parseInt(index));
      }
      if (slider == 3) {
        this.$refs.carouselMiniatures3[0].slideTo(parseInt(index));
      }
      if (slider == 4) {
        this.$refs.carouselMiniatures4[0].slideTo(parseInt(index));
      }
      if (slider == 5) {
        this.$refs.carouselMiniatures5[0].slideTo(parseInt(index));
      }
      if (slider == 6) {
        this.$refs.carouselMiniatures6[0].slideTo(parseInt(index));
      }
      if (slider == 7) {
        this.$refs.carouselMiniatures7[0].slideTo(parseInt(index));
      }
      if (slider == 8) {
        this.$refs.carouselMiniatures8[0].slideTo(parseInt(index));
      }
      if (slider == 9) {
        this.$refs.carouselMiniatures9[0].slideTo(parseInt(index));
      }
      if (slider == 10) {
        this.$refs.carouselMiniatures10[0].slideTo(parseInt(index));
      }
    },
    changeSliderOrganics: function (slider, index) {
      if (slider == 0) {
        this.$refs.carouselMiniaturesOrganics0[0].slideTo(parseInt(index));
      }
      if (slider == 1) {
        this.$refs.carouselMiniaturesOrganics1[0].slideTo(parseInt(index));
      }
      if (slider == 2) {
        this.$refs.carouselMiniaturesOrganics2[0].slideTo(parseInt(index));
      }
    },
    changeOnScroll: function () {
      var offset = window.pageYOffset;

      this.scroll = offset;
    },
    changeHeight: function () {
      if (this.$vuetify.breakpoint.lgAndDown) {
        this.barHeight = 80;
      }
      else if(this.$vuetify.breakpoint.width > 2299) {
        this.barHeight = 150;
      }
      else {
        this.barHeight = 100;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.menu-mobile {
  height: 100vh;
  padding-top: 100px;
  background: #f7f4ed;
}

.menu-arrow-products {
  font-size: 35px !important;
  transition: all 0.5s ease;

  &.rotate-icon {
    transition: all 0.5s ease;
    transform: rotate(180deg);
  }
}

.product-name-menu {
  display: block;
  margin-top: 10px;
  padding: 5px 10px;
  @media screen and(max-width: $xl) {
    margin-top: 4px;
  }
  @media screen and(max-width: $md) {
    text-align: center;
    margin-top: 16px;
  }

  &:hover {
    // opacity: 0.5;
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }

  &.router-link-active {
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }
}

.product-name-menu-mobile {
  &:hover {
    // opacity: 0.5;
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }

  &.router-link-active {
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }
}

.product-name-menu-no-sub {
  display: block;
  margin-top: 10px;
  @media screen and(max-width: $xl) {
    margin-top: 7px;
  }

  @media screen and(max-width: $lg) {
    > span {
      line-height: 1.2;
    }
  }
  @media screen and(max-width: $md) {
    text-align: center;
    margin-top: 16px;
  }

  // &:hover {
  //   opacity: 0.5;
  // }

  &:hover {
    // opacity: 0.5;
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }

  &.router-link-active {
    background: #e3d7b8;
    border-radius: 45px;
    padding: 5px 10px;
  }
}

.category-name-header {
  background: #0c762d;
  color: white;
  width: fit-content;
  border-radius: 35px;
  padding: 1px 60px;
  margin-bottom: 30px;
  font-size: 52px;

  @media screen and(max-width: $xxl) {
    font-size: 36px;
  }

  @media screen and(max-width: $xl) {
    font-size: 25px;
    padding: 1px 34px;
    margin-bottom: 22px;
  }

  @media screen and(max-width: $md) {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
  @media screen and(max-width: $sm) {
    font-size: 30px;
    padding: 10px;
    padding-left: 110px;
    padding-right: 15px;
    line-height: 0.8;
    text-align: left;
  }
}

.subcategory-name-header {
  margin-top: 22px;
  margin-bottom: 11px;
  line-height: 1.3;
  color: #616161;
  font-size: 36px;
  &:first-child {
    margin-top: 0px !important;
  }
  @media screen and(max-width: $xxl) {
    font-size: 24px;
  }
  @media screen and(max-width: $xl) {
    margin-top: 10px;
    font-size: 18px;
  }

  @media screen and(max-width: $md) {
    // padding-top: 30px;
  }

  &.color-green-subcategory {
    color: #115224;
    border-bottom: 2px solid #0c762d;
    padding-bottom: 10px;
    @media screen and(max-width: $xl) {
      padding-bottom: 6px;
    }
    @media screen and(max-width: $md) {
      margin-left: 200px;
      margin-right: 200px;
      padding-bottom: 12px;
    }

    @media screen and(max-width: $sm) {
      margin-left: 70px;
      margin-right: 70px;
      border-bottom: 1px solid #0c762d;
      padding-bottom: 12px;
    }
  }

  @media screen and(max-width: $xl) {
    margin-bottom: 14px;
    margin-top: 22px;
  }

  @media screen and(max-width: $lg) {
    line-height: 1.2;
  }
  @media screen and(max-width: $md) {
    margin-bottom: 24px;
    margin-top: 31px;
  }
  @media screen and(max-width: $sm) {
    margin-bottom: 24px;
  }
}

.MenuMobile {
  min-height: 0px;
  background: #f7f4ed;
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 6;
  user-select: none;

  @media screen and(max-width: 1162px) {
    top: 80px;
    padding: 0px 60px;
    padding-top: 21px;
    padding: 0px;
  }

  @media screen and(max-width: $sm) {
    top: 80px;
  }

  &.open {
    min-height: 100vh;
  }

  .hooper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.products-item {
  border-bottom: 1px solid gray;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;

  @media screen and(max-width: 1162px) {
    padding-bottom: 16px;
    padding-top: 22px;
  }

  @media screen and(max-width: $sm) {
    padding-bottom: 10px;
    padding-top: 18px;
  }
}

.padding-custom-menu {
  padding: 40px;
  min-height: calc(100vh - 40px);
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../../assets/img/menu-background.png);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position-y: bottom;
  // }

  @media screen and(max-width: $xl) {
    padding: 25px 40px 40px 40px;
  }
}

.subcategory-column {
  padding-right: 70px !important;
  @media screen and(max-width: $xl) {
    padding-right: 52px !important;
  }
  @media screen and(max-width: $lg) {
    padding-right: 40px !important;
  }
  @media screen and(max-width: $md) {
    padding-right: 0px !important;
    margin: 20px 0px;
  }
  &:last-child {
    padding-right: 0px !important;
  }
}

.margin-in-logo {
  @media screen and(max-width: $sm) {
    margin-left: 4px;
  }
}

.item-categories {
  margin-bottom: 10px;
}
.menu-complete {
  position: fixed;
  height: calc(100vh - 100px);
  overflow-y: auto;
  width: 100%;
  background: #fcf9f3;
  top: 100px;
  z-index: 6;
  scrollbar-width: 0;
  @media screen and(max-width: $xl) {
    height: calc(100vh - 80px);
    top: 80px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.modify-position {
    height: calc(100vh - 60px);
    top: 60px;
  }
  .background-menu {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../assets/img/menu-background.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: bottom;
    }
  }
  .menu-content {
    padding: 25px 20px;
    padding-bottom: 110px;
    @media screen and(max-width: $xl) {
      padding: 18px 20px;
      padding-bottom: 72px;
    }
  }
  .hooper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.menu-title {
  color: #073114;
  margin-bottom: 20px;
  font-size: 46px;
  @media screen and(max-width: $xxl) {
    font-size: 34px;
  }
  @media screen and(max-width: $xl) {
    margin-bottom: 0px;
    font-size: 22px;
  }
  > span {
    font-size: 74px;
    @media screen and(max-width: $xxl) {
      font-size: 56px;
    }
    @media screen and(max-width: $xl) {
      font-size: 34px;
    }
  }
}

.small-carousel {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background: rgba(44, 100, 0, 0.1);
  margin: auto;
  margin-bottom: 20px !important;
  position: relative;
  @media screen and(max-width: $xl) {
    width: 130px;
    height: 130px;
  }
  @media screen and(max-width: $md) {
    width: 120px;
    height: 120px;
    background: #d4ddc5;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  @media screen and(max-width: $sm) {
    width: 87px;
    height: 87px;
  }

  .hooper-slide {
    width: 100% !important;
  }
}

.miniature-carousel {
  // position: absolute;
  // opacity: 1;
  width: 140px;
  display: block;
  margin: auto;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // color: transparent;
  // background: red;
  // z-index: -1;
  // opacity: 0;

  // &.active-item {
  //   opacity: 1;
  //   z-index: 1;
  //   color: blue;
  //   background: green;
  // }
  @media screen and(max-width: $xl) {
    width: 113px;
  }
  @media screen and(max-width: $md) {
    width: 97px;
  }
  @media screen and(max-width: $sm) {
    width: 60px;
  }
}

.subcategory-organics {
  max-width: 200px;
  margin: auto;
}

.text-intro-menu-mobile {
  font-size: 28px;
  margin: 30px 0;
  @media screen and(max-width: $md) {
    margin: 51px 0;
    margin-bottom: 53px;
  }

  @media screen and(max-width: $sm) {
    margin: 30px 0;
    margin-top: 47px;
  }

  > span {
    font-size: 44px;
  }
}

.back-mobile {
  position: relative;
  padding: 10px 80px;
  @media screen and(max-width: $md) {
    padding: 10px 140px;
  }
  @media screen and(max-width: $sm) {
    padding: 10px 30px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url(../../assets/img/menu-background.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    pointer-events: none;
  }
}

.img-leaf {
  position: absolute;
  top: -9px;
  right: -6px;
  @media screen and(max-width: $xl) {
    width: 40px;
  }

  @media screen and(max-width: $md) {
    width: 37px;
  }
}
.organics-col-mobile {
  padding-bottom: 250px !important;
  @media screen and(max-width: $sm) {
    padding-bottom: 120px !important;
  }
}

.element-padding {
  padding-right: 100px !important;
  padding-left: 100px !important;

  @media screen and(max-width: $sm) {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}
.title-organics {
  font-size: 100px;
  @media screen and(max-width: $xxl) {
    font-size: 70px;
  }
  @media screen and(max-width: $xl) {
    font-size: 44px;
    // margin-top: 30px;
  }
}
.organics-title-mobile {
  @media screen and(max-width: $md) {
    font-size: 44px;
    margin-bottom: 29px;
  }
}
.close-lake-menu {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  @media screen and(min-width: $xxl) {
    width: 28px;
    height: 28px;
  }
  @media screen and(max-width: $xl) {
    right: 14px;
    width: 16px;
  }
}
</style>