<template>
  <v-app id="app">

    <Header/>
    <v-main>
      <transition name="fade-transition">
        <router-view ></router-view>
      </transition>
    </v-main>
    <!-- v-if="!(( $route.name == 'Recipe') && !$vuetify.breakpoint.xs)" -->
    <template>
      <Footer/>
    </template>
  </v-app>
</template>

<script>
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";

export default {
  name: "App",

  components:{
    Header,
    Footer,
  },

  data: () => ({
    change: 0
  }),
  methods:{
    changeThings: function(x){
      this.change = x;
    }
  }
};
</script>
